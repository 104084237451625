import React from "react";

const NotificationItems = () => {
	return (
		<div className="navbar__notification">
			<p>
				No new notifications. <br />
				You're all caught up!
			</p>
		</div>
	);
};

export default NotificationItems;
