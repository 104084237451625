import React, { useEffect } from "react";
import Apxor from "apxor";
const Promises = () => {
  useEffect(() => {
    Apxor.logEvent("ItemPageViewed", {
      "Item type": "Language",
      "Item name": "JavaScript",
      ItemTopic: "Promises",
    });
  }, []);
  return (
    <div id="article_Promises">
      <h1 id="title">Promises in Java Script</h1>
      <p>
        A Promise is a proxy for a value not necessarily known when the promise
        is created. It allows you to associate handlers with an asynchronous
        action's eventual success value or failure reason. This lets
        asynchronous methods return values like synchronous methods: instead of
        immediately returning the final value, the asynchronous method returns a
        promise to supply the value at some point in the future. A Promise is in
        one of these states: pending: initial state, neither fulfilled nor
        rejected. fulfilled: meaning that the operation was completed
        successfully. rejected: meaning that the operation failed. The eventual
        state of a pending promise can either be fulfilled with a value or
        rejected with a reason (error). When either of these options occur, the
        associated handlers queued up by a promise's then method are called. If
        the promise has already been fulfilled or rejected when a corresponding
        handler is attached, the handler will be called, so there is no race
        condition between an asynchronous operation completing and its handlers
        being attached.
      </p>
    </div>
  );
};

export default Promises;
