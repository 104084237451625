import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import Apxor from "apxor";
import CE from "apxor-qe";
import ApxorRTM from "apxor-rtm";
import { BrowserRouter } from "react-router-dom";

Apxor.init(
	"6895e33a-a3e3-48cc-b2b4-8047ac6a7b10",
	{
		plugins: ["ApxorRTM"],
		deps: [ApxorRTM, CE],
		version: "0.1",
		debug: true,
		level: "debug",
	},
	(e) => console.log("Successfully Initialized Apxor SDK:", e),
	(e) => console.log("Apxor SDK Initialization failed:", e)
); //Initializing Apxor

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById("root")
);
