import React from 'react'

function WebDesign() {
  return (
    <div>
      <br></br>
      <br></br>
      <h2 style={{ marginLeft: "50px" }}>Web Design</h2>
      <p style={{ marginLeft: "50px" }}>
        Web design refers to the design of websites that are displayed on the
        internet. It usually refers to the user experience aspects of website
        development rather than software development. Web design used to be
        focused on designing websites for desktop browsers; however, since the
        mid-2010s, design for mobile and tablet browsers has become
        ever-increasingly important. A web designer works on the appearance,
        layout, and, in some cases, content of a website. Appearance, for
        instance, relates to the colors, font, and images used. Layout refers to
        how information is structured and categorized. A good web design is easy
        to use, aesthetically pleasing, and suits the user group and brand of
        the website. Many webpages are designed with a focus on simplicity, so
        that no extraneous information and functionality that might distract or
        confuse users appears. As the keystone of a web designer’s output is a
        site that wins and fosters the trust of the target audience, removing as
        many potential points of user frustration as possible is a critical
        consideration. Two of the most common methods for designing websites
        that work well both on desktop and mobile are responsive and adaptive
        design. In responsive design, content moves dynamically depending on
        screen size; in adaptive design, the website content is fixed in layout
        sizes that match common screen sizes. Preserving a layout that is as
        consistent as possible between devices is crucial to maintaining user
        trust and engagement. As responsive design can present difficulties in
        this regard, designers must be careful in relinquishing control of how
        their work will appear. If they are responsible for the content as well,
        while they may need to broaden their skillset, they will enjoy having
        the advantage of full control of the finished product. Gestalt
        Psychology and Web Design: The Ultimate Guide Gestalt Psychology and Web
        Design:
      </p>

      <h3 style={{ marginLeft: "50px" }}>Figma</h3>
      <p style={{ marginLeft: "50px" }}>
        Figma is a cloud-based design tool that is similar to Sketch in
        functionality and features, but with big differences that make Figma
        better for team collaboration.Figma works on any operating system that
        runs a web browser. Macs, Windows PCs, Linux machines, and even
        Chromebooks can be used with Figma. It is the only design tool of its
        type that does this, and in shops that use hardware running different
        operating systems, everyone can still share, open, and edit Figma files.
        In many organizations, designers use Macs and developers use Windows
        PCs. What does Figma do to help bring these groups together? Figma’s
        universal nature prevents the annoyance of PNG-pong (where updated
        images are bounced back and forth between design team disciplines). In
        Figma, there is no need for a mediating mechanism to make design work
        available to everyone.
      </p>
    </div>
  );
}

export default WebDesign;
