import React from "react";
const CompanyInfo = () => {
  return (
    <div className="infoContainer">
      <div className="footerItems">
        <div className="footerItems__items">
          <a href="/about.html" target="_blank">
            ***IFRAMES***
          </a>
          <a href="/redirection.html" target="_blank">
            ***REDIRECTIONS***
          </a>
          <a href="/usecases.html" target="_blank">
            ***USECASES SUPPORTED***
          </a>
          <a href="/community.html" target="_blank">
            CONTACT US
          </a>
        </div>
      </div>
    </div>
  );
};

export default CompanyInfo;
