import React from "react";

export default function DataScience() {
  return (
    <div>
      <br></br>
      <br></br>

      <h1 style={{ margin: "30px", textAlign: "center" }}>Data Science</h1>
      <h2 style={{ margin: "30px", textAlign: "justify" }}>
        What is Data Science
      </h2>
      <p style={{ margin: "30px", textAlign: "justify" }}>
        Data science is a multidisciplinary field of study that applies
        techniques and tools to draw meaningful information and actionable
        insights out of noisy data. Involving subjects like mathematics,
        statistics, computer science and artificial intelligence, data science
        is used across a variety of industries for smarter planning and decision
        making. Data science is the realm of data scientists, who often rely on
        artificial intelligence, especially its subfields of machine learning
        and deep learning, to create models and make predictions using
        algorithms and other techniques.
      </p>
      <h2 style={{ margin: "30px", textAlign: "justify" }}>
        Data Science Use Cases
      </h2>
      <p style={{ margin: "30px", textAlign: "justify" }}>
        Data science is used by businesses of all kinds, from Fortune 50
        companies to fledgling startups, to look for connections and patterns
        and deliver breakthrough insights. That explains why data science is a
        rapidly growing field and revolutionizing many industries. More
        specifically, data science is used for complex data analysis, predictive
        modeling, recommendation generation and data visualization.<br></br>{" "}
        <h3>Analysis of Complex Data </h3>
        Data science allows for quick and precise analysis. With various
        software tools and techniques at their disposal, data analysts can
        easily identify trends and detect patterns within even the largest and
        most complex datasets. This enables businesses to make better decisions,
        whether it’s regarding how to best segment customers or conducting a
        thorough market analysis. <h3>Predictive Modeling</h3> Data science can
        also be used for predictive modeling. In essence, by finding patterns in
        data through the use of machine learning, analysts can forecast possible
        future outcomes with some degree of accuracy. These models are
        especially useful in industries like insurance, marketing, healthcare
        and finance, where anticipating the likelihood of certain events
        happening is central to the success of the business.
        <h3> Recommendation Generation</h3> Some companies, such as Netflix,
        Amazon and Spotify, rely on data science and big data to generate
        recommendations for their users based on their past behavior. It’s
        thanks to data science that users of these and similar platforms can be
        served up content that is uniquely tailored to their preferences and
        interests.<h3> Data Visualization </h3>Data science is also used to
        create data visualizations — think graphs, charts, dashboards — and
        reporting, which helps non-technical business leaders and busy
        executives easily understand otherwise complex information about the
        state of their business.
      </p>
      <h2 style={{ margin: "30px", textAlign: "justify" }}>
        Data Science Tools
      </h2>
      <p style={{ margin: "30px", textAlign: "justify" }}>
        Data science professionals typically require an arsenal of data science
        tools and programming languages to use throughout their careers. These
        are some of the more popular options being used today:
      </p>
      <h3 style={{ margin: "30px", textAlign: "justify" }}>
        Common Data Science Programming Languages
      </h3>
      <ul style={{ margin: "30px", textAlign: "justify" }}>
        <li>Python</li>
        <li>R</li>
        <li>SQL</li>
        <li>C/C++</li>
      </ul>
      <h3 style={{ margin: "30px", textAlign: "justify" }}>
        Popular Data Science Tools
      </h3>
      <ul style={{ margin: "30px", textAlign: "justify" }}>
        <li>Apache Spark (data analytics tool)</li>
        <li>Apache Hadoop (big data tool)</li>
        <li>KNIME (data analytics tool)</li>
        <li>Microsoft Excel (data analytics tool)</li>
        <li>
          Microsoft Power BI (business intelligence data analytics and data
          visualization tool)
        </li>
        <li> MongoDB (database tool)</li>
        <li>Qlik (data analytics and data integration tool)</li>
        <li>QlikView (data visualization tool)</li>
        <li>SAS (data analytics tool)</li>
      </ul>
      <h2 style={{ margin: "30px", textAlign: "justify" }}>
        Data Science Life Cycle
      </h2>
      <p style={{ margin: "30px", textAlign: "justify" }}>
        Data science can be thought of as having a five-stage lifecycle:
        <h3> Capture</h3>
        This stage is when data scientists gather raw and unstructured data. The
        capture stage typically includes data acquisition, data entry, signal
        reception and data extraction.<h3> Maintain </h3> This stage is when
        data is put into a form that can be utilized. The maintenance stage
        includes data warehousing, data cleansing, data staging, data processing
        and data architecture. <h3>Process</h3> This stage is when data is
        examined for patterns and biases to see how it will work as a predictive
        analysis tool. The process stage includes data mining, clustering and
        classification, data modeling and data summarization. <h3>Analyze</h3>{" "}
        This stage is when multiple types of analyses are performed on the data.
        The analysis stage involves data reporting, data visualization, business
        intelligence and decision making. <h3>Communicate</h3> This stage is
        when data scientists and analysts showcase the data through reports,
        charts and graphs. The communication stage typically includes
        exploratory and confirmatory analysis, predictive analysis, regression,
        text mining and qualitative analysis.
      </p>
      <h2 style={{ margin: "30px", textAlign: "justify" }}>
        What are Data Science Techniques?
      </h2>
      <p style={{ margin: "30px", textAlign: "justify" }}>
        There are lots of data science techniques with which data science
        professionals must be familiar in order to do their jobs. These are some
        of the most popular techniques: <h3>Regression</h3> A type of supervised
        learning, regression analysis in data science allows you to predict an
        outcome based on multiple variables and how those variables affect each
        other. Linear regression is the most commonly used regression analysis
        technique. <h3>Classification </h3>Classification in data science refers
        to the process of predicting the category or label of different data
        points. Like regression, classification is a subcategory of supervised
        learning. It’s used for applications such as email spam filters and
        sentiment analysis. <h3>Clustering</h3> Clustering, or cluster analysis,
        is a data science technique used in unsupervised learning. During
        cluster analysis, closely associated objects within a data set are
        grouped together, and then each group is assigned characteristics.
        Clustering is done to reveal patterns within data — typically with
        large, unstructured data sets.<h3>Anomaly Detection </h3> Anomaly
        detection, sometimes called outlier detection, is a data science
        technique in which data points with relatively extreme values are
        identified. Anomaly detection is used in industries like finance and
        cybersecurity.
      </p>
    </div>
  );
}
