import React from 'react'

export default function FrontEndEngineer() {
  return (
    <div>
      <br></br>
      <br></br>

      <h1 style={{ margin: "30px", justifyContent: "center" }}>
        Front End Web Development
      </h1>
      <p style={{ margin: "30px", textAlign: "justify" }}>
        When you are researching about any of the particular topic over
        internet, you will proceed with websites which are user friendly,
        attractive, and easy to play around. So, behind these attractive
        websites and its options which we can interact with through a web
        browser is known as Front-end Development. Front-end Development
        involves creating user interfaces, so that users can interact and see
        directly on a web application. The challenging part in Front-end
        Development is the continues upgradation and introduction of its tools
        and its technologies which makes the developer upgrade every time as the
        technology come up with new releases. Front-end views enable you to
        interact with the web and its actions. To give that attractive looks,
        action and design are developed using the three best front end
        technologies and the fundamental technologies are – HTML – Hypertext
        Markup Language, CSS – Cascading Style Sheet, JS – JavaScript.
      </p>
      <h2 style={{ margin: "30px", textAlign: "justify" }}>HTML5</h2>
      <p style={{ margin: "30px", textAlign: "justify" }}>
        HTML5 Boilerplate is known as the most popular front-end template or
        most popular front end technologies. It helps you to build fast,
        adaptable and robust web applications.HTML5 Boilerplate offers a
        professional front-end template with a set of HTML5 ready features and
        its elements. It can be downloaded from HTML5 Boilerplate, which
        includes all of its documentations, explanations and folders. Also,
        there is customizable option, so you can choose the elements that you
        need. HTML5 Boilerplate provides huge set of documentations, this is
        going to be the best type of help for any technical person who might be
        looking for suggestions or answers. Almost everything is included in the
        doc folder, if you want to know on each topic refer to TOC.md file HTML5
        Boilerplate template provides two types of CSS, they are: Noramlize.css
        and main.css. The normalized CSS helps the browsers to render the
        elements in a constituent way. In main.css, where all the custom code
        will be written, and you have to format your site with CSS.{" "}
      </p>
      <h2 style={{ margin: "30px", textAlign: "justify" }}>React JS</h2>
      <p style={{ margin: "30px", textAlign: "justify" }}>
        If you are new to web technologies like React.js, you might have come
        with the thought that, why React is known as popular front end
        frameworks, why everyone should use it. You are in the correct place to
        clear up all your doubts. React is known as a remarkably flexible
        library. Once you understand the concepts of react, you can use it on a
        vast variety of application platforms to build the great quality of user
        interfaces. React is known as Library it is not a framework. React
        provides great developer experience. ReactJS APIs are easy to
        understand. React uses HTML inside JavaScript which is known as JSX.
        Here you can play with HTML as well as JavaScript. React has Facebook’s
        support, as it is widely used in Facebook app, websites and in
        Instagram. The topmost contributors on GitHub are employees of Facebook.
        Also React.org provides a set of blogs which gives the detailed
        explanation of each topic of react JS.{" "}
      </p>
      <h2 style={{ margin: "30px", textAlign: "justify" }}>Angular</h2>
      <p style={{ margin: "30px", textAlign: "justify" }}>
        Angular is an open-source, JavaScript framework written in TypeScript.
        Angular’s primary purpose is to develop single-page applications because
        of this feature it got spread widely all over the world. Another main
        reason for the popularity of Angular is the Front-end Development. To
        gain attraction we should have web and mobile applications in a very
        attractive manner, this can be done using angular JS. Company websites
        like PayPal, Netflix, Google, Virgin America and many more are built
        using Angular. Angular provides, databinding, MVC – Model View
        Controller design pattern, dependency injection and cross platform
        support. Also, Angular is very easy to learn and implement. Since it is
        an open-source framework the affordability of angular is cost free.
        Angular JS provides completely declarative UI expressions so, it can
        provide the structured and understandable components to manipulate.
      </p>
      <h2 style={{ margin: "30px", textAlign: "justify" }}>Vue JS</h2>
      <p style={{ margin: "30px", textAlign: "justify" }}>
        If you think JavaScript as the English Alphabets, then Vue JS becomes
        the phrasebook which enables the developer to build effective sentences
        to communicate with. Vue JS is an open-source framework. As the core
        layer of the Vue JS is mainly focused on view part of the applications
        only. Vue JS is like Angular and React JS. The basic idea behind the
        development of Vue JS is to achieve the best results with minimal
        effort, so that users can code and build with few lines. The best part
        is Vue JS does not require deep learning, so it is most beneficial for
        new programmers. With respect to learning perspective, Vue JS requires
        only the basic knowledge of HTML, CSS, and JavaScript which is unlike
        Angular and React as it requires the knowledge of additional programming
        languages. Vue JS functions are readily accessible, and programmer can
        easily name the functions as per their requirements.
      </p>
      <h2 style={{ margin: "30px", textAlign: "justify" }}>Next JS</h2>
      <p style={{ margin: "30px", textAlign: "justify" }}>
        Next JS is a flexible framework of React, that gives you readymade
        building block to create faster web applications. Next JS handles the
        configurations and tooling which are needed for React and provides
        additional structure and optimization for your applications. No matter
        if you are an individual developer or a team player, you can handle
        React and Next JS to build highly scalable, dynamic interactive web
        applications. Next JS provides a framework to structure the application.
        Where a developer can only have to create a page and link to the
        component in the header, which avoids large lines of coding.
      </p>
    </div>
  );
}
