import React from 'react'

export default function Resources() {
  return (
    <div>
      <br></br>
      <br></br>
      <br></br>
      <h2 style={{ marginLeft: "50px" }}>Javacript Resources</h2>
      <a
        style={{ marginLeft: "50px" }}
        href="https://github.com/AkellaRaviTeja/JSExercises"
      >
        JS Exercises Repo 1
      </a>
      <br></br>
      <a
        style={{ marginLeft: "50px" }}
        href="https://github.com/theskinnycoder/js-exercises"
      >
        JS Exercises Repo 2
      </a>
      <br></br>
      <iframe
        width="560"
        height="315"
        style={{ marginLeft: "50px" }}
        src="https://www.youtube.com/embed/W6NZfCO5SIk"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>

      <h2 style={{ marginLeft: "50px" }}>GoLang Resources</h2>
      <iframe
        width="560"
        height="315"
        style={{ marginLeft: "50px" }}
        src="https://www.youtube.com/embed/yyUHQIec83I"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
      <h2 style={{ marginLeft: "50px" }}>ObjectiveC Resources</h2>
      <a
        style={{ marginLeft: "50px" }}
        href="https://developer.apple.com/library/archive/documentation/Cocoa/Conceptual/ProgrammingWithObjectiveC/Introduction/Introduction.html#//apple_ref/doc/uid/TP40011210"
      >
        Objective C
      </a>
      <br></br>
      <a
        style={{ marginLeft: "50px" }}
        href="https://developer.apple.com/library/archive/documentation/MacOSX/Conceptual/BPFrameworks/Tasks/CreatingFrameworks.html"
      >
        Framework
      </a>
      <h2 style={{ marginLeft: "50px" }}>Scala Resources</h2>
      <iframe
        width="560"
        height="315"
        style={{ marginLeft: "50px" }}
        src="https://www.youtube.com/embed/i9o70PMqMGY"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
      <h2 style={{ marginLeft: "50px" }}>Kubernetes Resources</h2>
      <iframe
        width="560"
        height="315"
        style={{ marginLeft: "50px" }}
        src="https://www.youtube.com/embed/s_o8dwzRlu4"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
      <h2 style={{ marginLeft: "50px" }}>Docker Resources</h2>
      <iframe
        width="560"
        height="315"
        style={{ marginLeft: "50px" }}
        src="https://www.youtube.com/embed/pTFZFxd4hOI"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
  );
}
